<template>
  <div class="referral-layout">
    <router-view />
  </div>
</template>

<script>
export default {
  mounted() {
    document.querySelector('.main-layout__content').classList.add('main-layout__content--referral');
  },
  destroyed() {
    document.querySelector('.main-layout__content').classList.remove('main-layout__content--referral');
  }
};
</script>

<style lang="scss">
.main-layout__content--referral {
  @apply tw-bg-secondary-light;

  .main-layout__content-body {
    @apply tw-max-w-full;
  }
}
</style>
